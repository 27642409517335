import { ThreeBounce } from "better-react-spinkit";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doDELETE, doGET, doPOST } from "../../util/HttpUtil";
import PrimaryButton from "../Buttons/PrimaryButton";
import DateSelectCustom from "../DateSelects/AntDateSelect";
import DocTypeSelect from "./DocTypeSelect";
import DropZone from "./Dropzone";
import DropzoneOnline from "./DropzoneOnline";
import { IoWarningOutline } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import DocCategoryService from "../../pages/DocCategory/DocCategoryService";
import "./styles.scss"
const DocFieldSkeleton = ({ showCategory, showExpiry }) => {
  return (
    <div className="fv_application_doc  rounded-2 border p-3">
      <div className="d-flex align-items-center row mb-2 ">
        <div className="col-3">
          <Skeleton height={30} />
        </div>
        <div className="col-3">

          <Skeleton height={30} />
        </div>

      </div>

      <div className="mx-1">
        <Skeleton height={50} />
      </div>
      <Row className="d-flex justify-content-between">
        {showCategory && (
          <div className="mt-2 col-lg-4 col-sm-12">
            <Skeleton height={30} />
          </div>
        )}
        {(showExpiry) && (
          <div className="mt-2  col-lg-5 col-sm-12">
            <Skeleton height={30} />
          </div>
        )}
        <div className="mt-2 col-lg-3 col-sm-12  d-flex justify-content-end">
          <Skeleton width={100} height={30} />
        </div>
      </Row>
    </div>
  );
};

const POLLING_INTERVAL = 15000; // 15 seconds
const POLLING_TIMEOUT = 90000;  // 1.5 minutes = 90 seconds

const DocField = ({
  key = 1,
  showCategory,
  hideUpload,
  data = { attachment: { name: "", info: "", refNo: "", urls: [] }, version: 0 },
  record_id = "",
  getRecordId = async () => { },
  module = "",
  inline,
  field,
  asLabel,
  showExpiry,
  type,
  ubo_id,
  onSubmit = () => { },
  seq,
  enableOnline,
  supportedFiles = ["image", "pdf"],
  single = false,
  error = "",
  validateByAi = false,
  onValidationStatusChange = () => { },

}) => {
  const { showError, showMessage, showConfirm } = useContext(DialogContext);
  const { t } = useContext(I18nContext);
  const [document, setDocument] = useState(data);
  const [loading, setLoading] = useState(false);

  // We store the AI validation status in the doc.
  // Values could be: "NotStarted", "In Progress", "Valid", "Invalid".
  const [validationStatus, setValidationStatus] = useState(document?.validationStatus || "NotStarted");

  const [isFetchingStatusOfAiValidation, setIsFetchingStatusOfAiValidation] = useState(false);

  useEffect(() => {
    try {
      DocCategoryService.fetchDocumentTypes()
    } catch (error) {

    }
  }, [])

  // For polling control
  const pollingTimerRef = useRef(null);
  const startPollingTimeRef = useRef(null);
  const documentRef = useRef(null);

  useEffect(() => {
    // If the doc is already in "In Progress" on mount, 
    // we might want to resume polling (corner case).
    if (validateByAi && document?._id && document?.attachment?.urls?.length && validationStatus !== "Valid" && validationStatus !== "Invalid" && startPollingTimeRef?.current == null) {
      checkValidationStatus()
    }

    return () => {
      clearPolling();
    };
  }, [document?.attachment?.urls?.map(url => url?.key).join(",")]);

  // Every time validationStatus changes, inform the parent
  useEffect(() => {
    onValidationStatusChange(document?._id, validationStatus);
  }, [validationStatus, document?._id]);


  // Clear any existing polling interval
  const clearPolling = () => {
    if (pollingTimerRef.current) {
      clearInterval(pollingTimerRef.current);
      pollingTimerRef.current = null;
      startPollingTimeRef.current = null;
    }
  };

  // Start polling for the doc's validation status
  const startPolling = () => {

    startPollingTimeRef.current = Date.now();

    pollingTimerRef.current = setInterval(async () => {
      await checkValidationStatus();
    }, POLLING_INTERVAL);

  };

  // Actually call your new API to fetch the doc validation status
  const checkValidationStatus = async (docId) => {
    const documentId = docId || documentRef.current?._id
    if (!record_id || !documentId || isFetchingStatusOfAiValidation) return;

    try {

      setIsFetchingStatusOfAiValidation(true);

      const response = await doGET(
        `/api/application-doc/init-status?applicationId=${record_id}&documentId=${documentId}`
      );

      const status = response?.data; // "Valid", "Invalid", or "In Progress"
      setValidationStatus(status);


      if (status === "Valid" || status === "Invalid") {
        clearPolling();
      } else {
        if (startPollingTimeRef.current) {
          const elapsed = Date.now() - startPollingTimeRef.current;
          if (elapsed >= POLLING_TIMEOUT) {
            // If we still haven't gotten final status, 
            // treat it as valid (or do whatever you prefer).
            setValidationStatus("Valid");
            clearPolling();
          }
        } else {
          startPolling();
        }

      }
      setIsFetchingStatusOfAiValidation(false);
    } catch (error) {
      showError(error);
      clearPolling();
      setIsFetchingStatusOfAiValidation(false);

    }
  };

  const getLatestVersionOfDoc = async (validateByAi, updatedField) => {
    if (validateByAi && updatedField === "docCategory_id") {
      try {
        const gridResp = await doGET(
          `/api/application-doc/grid?rows=-1&form_id=${record_id}${ubo_id ? `&ubo_id=${ubo_id}` : ""
          }${seq ? `&seq=${seq}` : ""}${type ? `&type=${type}` : ""}`
        );

        if (gridResp.status === 200) {
          return gridResp.data?.rows?.[0];
        }
      } catch (err) {
        showError(err);
        return []
      }
    }
    return []
  }

  const handleSaveCard = async (document, updatedField) => {

    // Define the supported file extensions based on the supportedFiles prop
    const supportedFileExtensions = supportedFiles.reduce((acc, fileType) => {
      if (fileType === "image") {
        acc.push(".png", ".gif", ".jpeg", ".jpg");
      } else if (fileType === "pdf") {
        acc.push(".pdf");
      }
      return acc;
    }, []);
    const invalidFiles = (document?.attachment?.urls ?? []).filter(url => {
      if (url != undefined && (url?.name || url?.key)) {
        const fileExtension = (url?.name ?? url?.key).slice((url?.name ?? url?.key).lastIndexOf("."));
        return !supportedFileExtensions.includes(fileExtension.toLowerCase());
      } return true
    });
    if (invalidFiles.length > 0) {
      showError(`Unsupported file format detected. Please upload only ${supportedFiles.join(" or ")} files.`);
      return;
    }

    setLoading(true);

    const formData = new FormData();
    const formUrls = document?.attachment?.urls?.filter(url => url?.key?.slice(1, 13) === "api/o/assets");
    document?.attachment?.urls?.forEach(url => {
      if (url.key.slice(1, 13) !== "api/o/assets") formData.append("file", url);
    });

    let recordId = record_id || await getRecordId();

    const latestDocument = await getLatestVersionOfDoc(validateByAi, updatedField);

    const docObject = validateByAi && updatedField === "docCategory_id" ? {
      ...latestDocument,
      docCategory_id: document?.docCategory_id,
    } : {
      record_id,
      module,
      expiry: document?.expiry,
      version: latestDocument?.version ?? document.version,
      docCategory_id: document?.docCategory_id,
      ubo_id,
      form_id: recordId,
      type,
      ...(seq && { seq }),
      attachment: { ...document.attachment, urls: formUrls },
      ...(data._id && { _id: data._id }),
      ...(field && { field })
    };
    formData.append("data", JSON?.stringify(docObject));

    try {

      const response = await doPOST(`/api/${module ? "file" : "application-doc"}/upload`, formData);
      if (response.status === 200) {
        showMessage(updatedField == "docCategory_id" ? "Updated Document Category" : "Document Uploaded");
        setDocument(response.data);
        documentRef.current = response.data;
        onSubmit();

        // If we want AI validation, let's trigger it now:
        if (validateByAi) {
          await checkValidationStatus(response.data?._id);
        } else {
          setValidationStatus("Valid");
        }

        return null
      } else if (response?.status === 413) {
        showError(response?.data);
        return null
      }
    } catch (error) {
      showError(error);
      return null
    } finally {
      setLoading(false);
    }
  };

  const handleAttachmentDelete = async (index) => {
    if (await showConfirm({ title: t("Do you want to delete file?"), description: t("This is an unrecoverable operation.") })) {
      try {
        const response = await doDELETE(`/api/${module ? "file" : "application-doc"}/delete/attachment`, {
          record_id, _id: document._id, module, index
        });
        if (response.status === 200) {
          setDocument(response.data);
          documentRef.current = response.data;
          showMessage("Deleted");

          if (!response.data.attachment?.urls?.length) {
            // stop polling
            clearPolling();
            setValidationStatus("NotStarted");
          }

          return true;
        }
      } catch (e) {
        showError(e);
      }
    }
    return false;
  };



  useEffect(() => {
    setDocument(data)
    documentRef.current = data;
  }, [JSON.stringify(data)]);

  const handleFieldChange = async (field, value) => {
    const updatedDocument = { ...document, [field]: value };
    const result = await handleSaveCard(updatedDocument, field);

  };

  function removeEmptyPTags(htmlString = "") {
  // This regex matches <p> tags that have only whitespace, &nbsp;, or <br> inside,
  // and removes them entirely.
  return htmlString.replace(/<p>(?:\s|&nbsp;|<br\s*\/?>)*<\/p>/gi, "");
}

  const renderValidationUI = () => {
    if (!validateByAi || !document?._id || !document.attachment?.urls?.length) {
      return null; // skip if not validating
    }

    switch (validationStatus) {
      case "Valid":
        return (
          <div className="px-2">

            <div className="alert alert-success d-flex align-items-center  mt-2 mb-0">
              <FaCheckCircle className="me-2" />
              <strong>Document Verified</strong>
            </div>

          </div>
        );
      case "Invalid":
        return (
          <div className="px-2">

            <div className="alert alert-warning d-flex align-items-center  mt-2 mb-0">
              <div className="">
                <strong className="d-flex align-items-center"><IoWarningOutline className="me-1" /> Invalid Document</strong>
                {DocCategoryService?.documentTypes?.[showCategory]?.warningMessage ? <>
                  <p
                    className="no-default-p mb-0 my-0"
                    dangerouslySetInnerHTML={{
                      __html: removeEmptyPTags(
                        DocCategoryService?.documentTypes?.[showCategory]?.warningMessage ?? ""
                      ),
                    }}
                  />
                    </> :
                  <>
                    <p className="mb-0">The document you provided may be invalid, We recommend uploading another document.</p>
                    <p className="mb-1">Please check the following:</p>
                    <ul className="my-0">
                      <li>Ensure the document is still valid (not expired).</li>
                      <li>Confirm that all information matches the details in your application.</li>
                      <li>Verify that the full document is clearly visible, including its borders.</li>
                    </ul>
                  </>
                }
              </div>
            </div>
          </div>
        );

      case "In Progress":
        return (
          <div className="px-2">

            <div className="alert alert-warning d-flex align-items-center  mt-2 mb-0">
              <ThreeBounce color="orange" size={8} />
              <span className="ms-2">Verifying document, please wait...</span>
            </div>
          </div>
        );
      default:
        return null;
    }
  };



  return (
    <div className="border border-light rounded-2 border" key={key}>
      <div className="position-relative p-3">
        {loading ? (
          <DocFieldSkeleton showCategory={showCategory} showExpiry={showExpiry} />
        ) : (
          <div>
            {(error?.length && !document?.attachment?.urls?.length && !loading) ? <em className="selectfield__error">{error}</em> : null}

            <div className="mx-1">
              {enableOnline ? <DropzoneOnline
                asLabel={asLabel}
                value={document?.attachment?.urls}
                inline={inline}
                onDeleteAttachment={handleAttachmentDelete}
                onChange={(v) => handleFieldChange('attachment', { ...(document?.attachment ?? {}), urls: v })}
              /> : <DropZone
                asLabel={asLabel}
                value={document?.attachment?.urls}
                inline={inline}
                onDeleteAttachment={handleAttachmentDelete}
                supportedFiles={supportedFiles}
                single={single}

                onChange={(v) => handleFieldChange('attachment', { ...(document?.attachment ?? {}), urls: v })}
              />}
            </div>
            <Row className="d-flex justify-content-between">
              {showCategory && (
                <DocTypeSelect
                  module={showCategory}
                  placeholder="Select Document Type"
                  label="Select Document Type"
                  value={document?.docCategory_id}
                  onChange={({ value }) => handleFieldChange('docCategory_id', value)}
                  className="mt-3 col-lg-4 col-sm-12"
                />
              )}
              {(asLabel && showExpiry) && (
                <DateSelectCustom
                  value={document?.expiry}
                  placeholder="dd/mm/yyyy"
                  onChange={(e) => handleFieldChange('expiry', e)}
                  label={t("Expiry Date")}
                  isDisabled={asLabel}
                  required
                />
              )}
              {showExpiry && (
                <DateSelectCustom
                  value={document?.expiry}
                  placeholder="dd/mm/yyyy"
                  onChange={(e) => handleFieldChange('expiry', e)}
                  label={t("Expiry Date")}
                  isDisabled={asLabel}
                  required
                  type="text"
                  className="mb-0 col-lg-5 col-sm-12"
                  minDate={new Date()}
                />
              )}
              {(!asLabel && !hideUpload) && (
                <div className="mt-3 col-lg-3 col-sm-12  mt-5 d-flex justify-content-end " >
                  <PrimaryButton
                    style={{ fontSize: 12 }}
                    loading={loading}
                    isDisabled={loading || !document?.attachment?.urls?.length || (showExpiry && !document?.expiry)}
                    onClick={() => handleSaveCard(key)}
                  >
                    {loading ? <ThreeBounce color="white" size={8} /> : t("Upload")}
                  </PrimaryButton>
                </div>
              )}

              {renderValidationUI()}


            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocField;
